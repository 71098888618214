<template>
  <div v-if="initialLoad">
    <div class="flex justify-center">
      <t-loader extraClass="mx-auto border-black" />
    </div>
  </div>
  <div v-else-if="academy.id">
    <h1 class="subtitle-text my-3">Datos personales</h1>
    <div class="flex flex-col md:flex-row mt-5 items-end">
      <t-input-group
        :feedback="
          showValidation && errors.first('first_name')
            ? errors.first('first_name')
            : ''
        "
        :variant="showValidation && errors.first('first_name') ? 'danger' : ''"
        class="w-full md:w-1/2 md:ml-2 md:mr-2"
        :label="'Nombres'"
      >
        <t-input
          v-validate="'required|max:200'"
          data-vv-validate-on="input"
          classes="bg-transparent"
          v-model="form.first_name"
          name="first_name"
          placeholder="Ingrese los nombres"
          autocomplete="off"
          :disabled="isLoading || disableInputs"
          type="text"
          :variant="
            showValidation && errors.first('first_name') ? 'danger' : ''
          "
        />
      </t-input-group>

      <t-input-group
        :feedback="
          showValidation && errors.first('last_name')
            ? errors.first('last_name')
            : ''
        "
        :variant="showValidation && errors.first('last_name') ? 'danger' : ''"
        class="w-full md:w-1/2 md:ml-2 md:mr-2"
        :label="'Apellidos'"
      >
        <t-input
          v-validate="'required'"
          data-vv-validate-on="input"
          classes="bg-transparent"
          v-model="form.last_name"
          name="last_name"
          placeholder="Ingrese los apellidos"
          autocomplete="off"
          :disabled="isLoading || disableInputs"
          type="text"
          :variant="showValidation && errors.first('last_name') ? 'danger' : ''"
        />
      </t-input-group>
    </div>

    <div class="flex flex-col md:flex-row items-end my-2">
      <t-input-group
        :feedback="
          showValidation && errors.first('gender') ? errors.first('gender') : ''
        "
        :variant="showValidation && errors.first('gender') ? 'danger' : ''"
        class="w-full md:w-1/2 md:ml-2 md:mr-2"
        :label="'Sexo'"
      >
        <t-select
          v-validate="'required'"
          data-vv-validate-on="input"
          v-model="form.gender"
          name="gender"
          placeholder="Seleccione una opción"
          :disabled="isLoading || disableInputs"
          textAttribute="name"
          valueAttribute="id"
          :options="[
            {
              id: 1,
              name: 'Femenino'
            },
            {
              id: 2,
              name: 'Masculino'
            }
          ]"
          :variant="showValidation && errors.first('gender') ? 'danger' : ''"
        ></t-select>
      </t-input-group>

      <t-input-group
        :feedback="
          showValidation && errors.first('dni') ? errors.first('dni') : ''
        "
        :variant="showValidation && errors.first('dni') ? 'danger' : ''"
        class="w-full md:w-1/2 md:mr-2"
        :label="'Cédula de identidad'"
      >
        <t-input
          v-validate="'required|numeric'"
          data-vv-validate-on="input"
          classes="bg-transparent"
          v-model="form.dni"
          name="dni"
          placeholder="Ingrese el número de cédula de identidad"
          autocomplete="off"
          :disabled="isLoading || disableInputs"
          type="text"
          :variant="showValidation && errors.first('dni') ? 'danger' : ''"
        />
      </t-input-group>
    </div>

    <div class="flex flex-col md:flex-row items-end justify-around my-2">
      <t-input-group
        :feedback="
          showValidation && errors.first('url_carnet_type')
            ? errors.first('url_carnet_type')
            : ''
        "
        :variant="
          showValidation && errors.first('url_carnet_type') ? 'danger' : ''
        "
        class="w-full md:w-1/3 md:mr-0 md:pr-2"
        :label="'Foto tipo carnet'"
      >
        <cropper-upload
          :file.sync="form.url_profile_picture"
          :externalUrl="
            typeof form.url_profile_picture === 'string'
              ? form.url_profile_picture
              : null
          "
          title="Foto tipo carnet"
          :aspectRatio="3/4"
          height="250px"
          backgroundColor="bg-white"
        />
      </t-input-group>

      <t-input-group
        :feedback="
          showValidation && errors.first('url_dni_picture')
            ? errors.first('url_dni_picture')
            : ''
        "
        :variant="
          showValidation && errors.first('url_dni_picture') ? 'danger' : ''
        "
        class="w-full md:w-1/3 md:mr-0 md:pr-2"
        :label="'Foto de cédula de identidad'"
      >
        <t-upload-file
          :disabled="isLoading || disableInputs"
          :file.sync="form.url_dni_picture"
          :externalUrl="
            typeof form.url_dni_picture === 'string' ? form.url_dni_picture : ''
          "
          accept="image/*"
        />
      </t-input-group>

      <t-input-group
        :feedback="
          showValidation && errors.first('url_carnet_picture')
            ? errors.first('url_carnet_picture')
            : ''
        "
        :variant="
          showValidation && errors.first('url_carnet_picture') ? 'danger' : ''
        "
        class="w-full md:w-1/3 md:mr-0 md:pr-2"
        :label="'Foto del carnet'"
      >
        <t-upload-file
          :disabled="isLoading || disableInputs"
          :file.sync="form.url_carnet_picture"
          :externalUrl="
            typeof form.url_carnet_picture === 'string'
              ? form.url_carnet_picture
              : ''
          "
          accept="image/*"
        />
      </t-input-group>
    </div>

    <div class="flex flex-col md:flex-row items-end my-2">
      <t-input-group
        :feedback="
          showValidation && errors.first('phone') ? errors.first('phone') : ''
        "
        :variant="showValidation && errors.first('phone') ? 'danger' : ''"
        class="w-full md:w-1/2 md:mr-2"
        :label="'Teléfono móvil'"
      >
        <t-input
          v-validate="'required|phone'"
          data-vv-validate-on="input"
          classes="bg-transparent"
          v-model="form.phone"
          name="phone"
          placeholder="Ingrese el teléfono móvil"
          autocomplete="off"
          :disabled="isLoading || disableInputs"
          type="text"
          :variant="showValidation && errors.first('phone') ? 'danger' : ''"
        />
      </t-input-group>

      <t-input-group
        :feedback="
          showValidation && errors.first('instagram')
            ? errors.first('instagram')
            : ''
        "
        :variant="showValidation && errors.first('instagram') ? 'danger' : ''"
        class="w-full md:w-1/2 md:mr-2"
        :label="'Usuario de Instagram'"
      >
        <t-input
          classes="bg-transparent"
          v-model="form.instagram"
          name="instagram"
          placeholder="Ingrese el usuario de Instagram"
          autocomplete="off"
          :disabled="isLoading || disableInputs"
          type="text"
          :variant="showValidation && errors.first('instagram') ? 'danger' : ''"
        />
      </t-input-group>
    </div>

    <div class="flex flex-col md:flex-row items-end my-2">
      <t-input-group
        :feedback="
          showValidation && errors.first('email') ? errors.first('email') : ''
        "
        :variant="showValidation && errors.first('email') ? 'danger' : ''"
        class="w-full md:w-1/2 md:mr-2"
        :label="'Correo electrónico'"
      >
        <t-input
          v-validate="'required|email'"
          data-vv-validate-on="input"
          classes="bg-transparent"
          v-model="form.email"
          name="email"
          placeholder="Ingrese el correo electrónico"
          autocomplete="off"
          :disabled="isLoading || disableInputs"
          type="email"
          :variant="showValidation && errors.first('email') ? 'danger' : ''"
        />
      </t-input-group>
      <t-input-group
        :feedback="
          showValidation && errors.first('address')
            ? errors.first('address')
            : ''
        "
        :variant="showValidation && errors.first('address') ? 'danger' : ''"
        class="w-full md:w-1/2 md:mr-2"
        :label="'Dirección de habitación'"
      >
        <t-input
          v-validate="'required'"
          data-vv-validate-on="input"
          classes="bg-transparent"
          v-model="form.address"
          name="address"
          placeholder="Ingrese la dirección"
          autocomplete="off"
          :disabled="isLoading || disableInputs"
          type="text"
          :variant="showValidation && errors.first('address') ? 'danger' : ''"
        />
      </t-input-group>
    </div>

    <h1 class="subtitle-text my-3">Datos laborales</h1>

    <div class="flex flex-col md:flex-row items-start mt-5">
      <t-input-group
        :feedback="
          showValidation && errors.first('profession')
            ? errors.first('profession')
            : ''
        "
        :variant="showValidation && errors.first('profession') ? 'danger' : ''"
        class="w-full md:w-1/2 md:mr-2"
        :label="'Profesión'"
      >
        <t-input
          classes="bg-transparent"
          v-model="form.profession"
          name="profession"
          placeholder="Ingrese su profesión"
          autocomplete="off"
          :disabled="isLoading || disableInputs"
          type="text"
          :variant="
            showValidation && errors.first('profession') ? 'danger' : ''
          "
        />
      </t-input-group>

      <t-input-group
        :feedback="
          showValidation && errors.first('id_carnet_type')
            ? errors.first('id_carnet_type')
            : ''
        "
        :variant="
          showValidation && errors.first('id_carnet_type') ? 'danger' : ''
        "
        class="w-full md:w-1/2 md:mr-4"
        :label="'Tipo de carnet'"
      >
        <t-select
          v-validate="'required'"
          data-vv-validate-on="input"
          v-model="form.id_carnet_type"
          name="id_carnet_type"
          placeholder="Seleccione una opción"
          :disabled="isLoading || disableInputs"
          textAttribute="name"
          valueAttribute="id"
          :options="carnetTypes"
          :variant="
            showValidation && errors.first('id_carnet_type') ? 'danger' : ''
          "
        ></t-select>
      </t-input-group>
    </div>
    <div class="flex justify-end items-center mt-5">
      <t-button @click="isValid"
        >Guardar <t-loader v-if="isLoading" extraClass="ml-2"
      /></t-button>
    </div>
  </div>
  <div v-else>
    <div class="flex justify-center flex-col">
      <div class="mx-auto">
        <img src="@/assets/img/error/403.svg" alt="No autorizado" />
      </div>
      <div class="mx-auto">
        <p class="text-primary-700 font-bold">
          Lo sentimos, no puedes registrarte si no utilizas el link provisto por
          tu academia
        </p>
      </div>
    </div>
  </div>
</template>
<script>
const notify = () => import("@/utils/notify.js")
export default {
  name: "AcademyForm",
  props: {
    disableInputs: {
      type: Boolean,
      default: false
    },
    initialLoad: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    academy: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      form: {
        first_name: null,
        last_name: null,
        gender: null,
        dni: null,
        phone: null,
        email: null,
        address: null,
        id_carnet_type: null,
        url_carnet_picture: null,
        url_dni_picture: null,
        url_profile_picture: null
      },
      showValidation: false,
      carnetTypes: []
    }
  },
  methods: {
    getItems(route, object) {
      this.$store
        .dispatch("global/getItems", {
          route: route + "/all",
          noPaginate: true,
          isMaster: true,
          orderKey: "name",
          orderValue: "asc",
          params: {
            active: 1
          }
        })
        .then((response) => {
          this[object] = response.data
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    isValid() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid === true) {
          if (!this.form.url_profile_picture) {
            this.$snotify.error("La foto tipo carnet es obligatoria", "Error", {
              timeout: 5000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              titleMaxLength: 40,
              bodyMaxLength: 100,
              position: "rightBottom"
            })
            return
          }
          if (!this.form.url_dni_picture) {
            this.$snotify.error(
              "La foto de la cédula es obligatoria",
              "Error",
              {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                titleMaxLength: 40,
                bodyMaxLength: 100,
                position: "rightBottom"
              }
            )
            return
          }
          if (!this.form.url_carnet_picture) {
            this.$snotify.error("La foto del carnet es obligatoria", "Error", {
              timeout: 5000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              titleMaxLength: 40,
              bodyMaxLength: 100,
              position: "rightBottom"
            })
            return
          }
          this.$emit("submit")
          this.$emit("update:isLoading", true)
        }
      })
    }
  },
  created() {
    this.showValidation = true
    this.getItems("/carnet_type", "carnetTypes")
  },
  watch: {}
}
</script>
